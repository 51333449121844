import {
	addRegressionTestingAttributes,
	addRequiredFieldsToForm,
	addVWOTestQueryParam,
	checkStartStep,
	generateSession,
	initAutofill,
	initFormDynamicContent,
	initFormElements,
	initFormSubmit,
	initHvacFuelTypeBlock,
	initHvacWouldReplaceBlock,
	initLeadTokenScriptsTriggers,
	initNextButtonAbusePrevention,
	initNumericOnlyFields,
	initQSForm,
	initSaveQueryParamsToSessionStorage,
	initShowSecureText,
	initTooltips,
	initTrackZip1Change,
	initUserInformationStorage,
	maskPhoneFields,
	observeVWOVariation,
	populateTCPAField,
	prepopFormFromURL,
	trackMouseMovement,
	unmarkFieldAsInvalid,
	watchHashChange,
	updateTabIndexes,
	getEddieData
} from '../../helpers';
import { isConfigValid } from '../../validators';

window.isNewModForm = true;

export function init(options) {
	window.modForm.opts = { ...window.modForm.opts, ...options };
	window.modForm.opts.landingPageNoQuery = window.modForm.opts.landingPage.split('?')[0];
	window.modForm.opts.fullPageUrl = window.location.href;
	window.isNonTyp = true;
	if (window.modForm.opts.useStepNameHash && window.location.hash !== '') {
		window.history.replaceState({}, document.title, window.location.href.split('#')[0]);
	}

	if (isConfigValid()) {
		// Init steps
		window.modForm.opts.steps = window.modForm.opts.form.querySelectorAll(window.modForm.opts.stepsSelector);
		// Init form elements
		initFormElements();

		// Assign URL GET params to window.modForm.opts.getVars
		window.modForm.opts.getVars = window.modUtils.getUrlParamsToObject(window.modForm.opts.landingPage);

		// If there's a query parameter that starts with vwo-test we add it to the body
		if (window.location.search.indexOf('vwo-test') > 0) {
			addVWOTestQueryParam();
		}

		// Change form dynamic content
		initFormDynamicContent();

		// Add required fields to the form
		addRequiredFieldsToForm();

		// get the eddie values and pre populate into the form
		getEddieData();

		if (window.modForm.opts.prepopulateUserInfo) {
			// Get user obj from cookie
			window.modForm.userObj = window.modUtils.getCookie('userInfo') ? JSON.parse(window.modUtils.getCookie('userInfo')) : {};
		}

		// Prepop form from URL
		prepopFormFromURL();

		// Check if step directive is in the URL
		checkStartStep();

		addRegressionTestingAttributes();

		// Generate user session
		generateSession();

		initNextButtonAbusePrevention();

		if (!window.sessionStorage.getItem('queryParams')) {
			initSaveQueryParamsToSessionStorage();
		}

		// Init if vertical = contractor
		if (window.modForm.opts.vertical === 'contractor') {
			window.modForm.initContractorForm();
			window.modForm.opts.useContractorApi = true;
		}

		// Init if it's a Quinstreet form
		if (window.modForm.opts.useQSApi) {
			initQSForm();
		}

		// Mask phone field & allow digits only
		initNumericOnlyFields(window.modForm.opts.maskedPhoneFields);
		maskPhoneFields(window.modForm.opts.maskedPhoneFields);

		// Numeric only fields
		initNumericOnlyFields(window.modForm.opts.numericsOnlyFields);

		// Remove error class when inputs change
		window.modForm.opts.form.addEventListener('input', function(e) {
			unmarkFieldAsInvalid(e.target);
		});

		// Init hvacFuelType block show/hide (HVAC only)
		if (window.modForm.opts.hvacVerticalName === window.modForm.opts.vertical) {
			initHvacFuelTypeBlock();
			initHvacWouldReplaceBlock();
		}

		// Init form submit
		if (window.modForm.opts.useContractorApi) {
			window.modForm.initContractorFormSubmit(); // need to merge in mod-form
		} else {
			// Tasks to be done on interacting with the form
			window.modForm.opts.form.addEventListener('click', function() {
				populateTCPAField();
				if (window.innerWidth < 768) {
					updateTabIndexes();
				}
			}, { once: true });
			initFormSubmit();
		}
		// Init lead token scripts triggers
		initLeadTokenScriptsTriggers();

		if (window.modForm.opts.prepopulateUserInfo) {
			initUserInformationStorage();
		}

		// Init autofill
		if (window.modForm.opts.useAutofill) {
			initAutofill();
		}

		if (window.modForm.opts.hashStepTracking && window.modForm.opts.vertical !== 'contractor') {
			watchHashChange();
		}

		if (window.modForm.opts.useSmoothScrolling) {
			window.modUtils.initSmoothScrolling();
		}

		if (window.modForm.opts.useTooltips) {
			initTooltips();
		}

		if (window.modForm.opts.showSecureText) {
			initShowSecureText();
		}

		if (window.modForm.opts.useObserveVWOVariation) {
			observeVWOVariation(window.modForm.opts.observeVWOVariationCallback);
		}

		if (window.modForm.opts.trackZip1ChangeDynamically) {
			initTrackZip1Change();
		}

		if (window.modForm.opts.addAccessibilityUpdates) {
			trackMouseMovement();
		}

		// Need to clear out the widget experience on back button (runs off a specific param)
		window.modUtils.refreshCachedExperienceOnBack();
	}
}
