/*
 * Init for showing "Your Information is secure" in PII steps
 */
export function initShowSecureText() {
	let secureText = '<div class="secure-text secure-text--dark"><b>Your information is secure</b></div>';
	if (window.modForm.opts.secureTextContent) {
		secureText = `<div class="secure-text secure-text--dark secure-text--custom"><b>${window.modForm.opts.secureTextContent}</b></div>`;
	}
	window.modForm.opts.secureTextStepsSelector.forEach(function(stepSelector) {
		const $stepButtonSelector = document.querySelector(`${stepSelector} .btn`);
		if ($stepButtonSelector) {
			if (window.modForm.opts.secureTextPosition === 'below-cta') {
				$stepButtonSelector.insertAdjacentHTML('afterend', secureText);
			} else {
				$stepButtonSelector.insertAdjacentHTML('beforebegin', secureText);
			}
		}
	});
}
