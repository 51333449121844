/* global Promise */
import {
	getCurrentStep,
	focusOnFirstErrorOnCurrentStep,
	initFormSubmitLoader,
	switchFormLoadingState,
	populateFormWithZipData,
	populateLocationFieldsDynamically,
	populateInput,
	showNextStep,
	trackInvalidFieldsInHeap,
	markFieldAsInvalid,
	getFormData,
	preprocessQSFormData,
	preprocessContractorFormData,
	submitForm,
	showStep,
	hashStepTracking,
	getGeolocationData
} from '../../../helpers';
import { isFormValid, isVisible, isZipCodeValid } from '../../../validators';

export function formSubmitCallback(e) {
	e.stopImmediatePropagation();
	e.preventDefault();

	const $activeStep = getCurrentStep();

	// Validate step fields
	if (!isFormValid($activeStep)) {
		focusOnFirstErrorOnCurrentStep();
		return;
	}

	// If not last step
	if (!window.modForm.isCurrentStepLast()) {
		// Add required elements for final form submit animation if current step is the one before last step
		const currentStepIndex = Array.from(window.modForm.opts.steps).indexOf(getCurrentStep()) + 1;
		if (currentStepIndex === window.modForm.opts.steps.length - 1 && (window.modForm.opts.showLoadingOverlay || window.modForm.opts.showRedirectOverlay) && !window.modForm.opts.isLoaderLoaded) {
			initFormSubmitLoader();
			// This is added as a part of WEBP-1379/LP-3270 to send gelocation values as a part of lead to measure the accuracy of Geolookup endpoint
			getGeolocationData();
		}
		// Validate zip if step contains it
		const zipFields = window.modForm.opts.trackZip1ChangeDynamically ? 'input[name="zip"]' : 'input[name="zip"], input[name="zip1"]';
		let $stepZipField = $activeStep.querySelectorAll(zipFields)[0];
		const editZipInput = $activeStep.querySelectorAll(window.modForm.zipEditInput)[0];

		if (window.modForm.retryZipValidation) {
			if (editZipInput && isVisible(editZipInput)) {
				$stepZipField = editZipInput;
			} else {
				$stepZipField = window.modForm.opts.zipField[0];
			}
		}
		if (($stepZipField && !window.modForm.isZipValid) || window.modForm.retryZipValidation) {
			switchFormLoadingState(true);
			isZipCodeValid($stepZipField.value, function(response) {
				switchFormLoadingState(false);
				// If success..
				if (response.success) {
					// Populate city & state
					if (response.data) {
						populateFormWithZipData(response.data);
						populateLocationFieldsDynamically(response.data);
						window.modForm.opts.zipCode = response.data.zip;

						// Send zip code to Invoca
						if (typeof dataLayer !== 'undefined' && window.Invoca) {
							window.dataLayer.push({
								zip_code: response.data.zip // eslint-disable-line camelcase
							});
						}

						// Fire callback
						if ('function' === typeof window.modForm.opts.zipCodeValidated) {
							window.modForm.opts.zipCodeValidated(response.data);
						}
					}

					// Update other zips
					if ($stepZipField.name === 'zip1') {
						populateInput('zip', $stepZipField.value);
					}
					showNextStep();
				} else { // or mark field invalid on error
					trackInvalidFieldsInHeap('Zip');
					markFieldAsInvalid($stepZipField);
					focusOnFirstErrorOnCurrentStep();
				}
			});
		} else {
			showNextStep();
		}
	} else { // Submit whole form if on last step
		new Promise(function(resolve) {
			let response = {
				status: true
			};
			// validate all the steps again
			window.modForm.opts.steps.forEach(async function(step, index) {
				const hasExtraStep = step.querySelector('.step__extra');
				if (!step.classList.contains('skipped') && !hasExtraStep && !isFormValid(step)) {
					response = {
						status: false,
						step
					};
				}
				if (window.modForm.opts.steps.length - 1 === index) {
					resolve(response);
				}
			});
		}).then((response) => {
			if (!response.status) {
				const step = response?.step;
				hashStepTracking();
				showStep(step?.id);
				focusOnFirstErrorOnCurrentStep();
				return;
			} else {
				// Get form data
				let formData = getFormData();

				// Landing Page GA Conversion Intent Event
				window.modUtils.gaSend({
					hitType: 'event',
					eventCategory: 'Homeowner Lead Form',
					eventAction: 'Clicked Submit',
					eventLabel: 'S3 Landing Page'
				}, window.modForm.opts.gaTracker);

				// Preprocess it
				if (window.modForm.opts.useContractorApi) {
					formData = preprocessContractorFormData(formData);
				} else {
					formData = preprocessQSFormData(formData);
				}
				submitForm(formData);
			}
		});
	}
}
