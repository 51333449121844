/**
 * Fetches and initializes the geolocation script to store the user's location data, if not present already.
 * 
 * This function utilizes the HS Geolookup endpoint (WEBP-1379) to retrieve the user's geolocation.
 * It is designed to assess and measure the accuracy and performance of the Geolookup endpoint.
 * 
 * Note: This function may be removed after the completion of the analysis and performance evaluation.
 */
function initGeolocation() {
	window.geolocation.useHSGeolookupEndPoint = true;
	window.geolocation.init();
}

export function getGeolocationData() {
	if (!window.geolocation) {
		window.modUtils.fetchScript('resources/scripts/geolocation/geolocation.min.js');
		document.addEventListener('geolocation.scriptLoaded', function() {
			initGeolocation();
		});
	} else if (!Object.keys(window.geolocation.currentLocation).length) { // Initialising the geoloc script, if its not initialised at site level
		initGeolocation();
	}
}