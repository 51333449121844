import { defaultQuadLinkValues } from './defaultQuadLinkValues';

/**
 * Get default quad link based on page domain
 * @returns {Object} Quad Link object value
 */

export function getDefaultQuadByDomain() {
	const page = window.modForm.opts.websiteName.replace(/(qa\.?|staging\.?|www\.?)/g, '');

	if (page.includes('bestcompany')) {
		let service = window.modForm.opts.landingPage.split('/');
		service = service[2].replace(/-/g, '');

		return defaultQuadLinkValues[page][service];
	} else if (page.indexOf('quotes.improvementcenter.com') > -1 || page.indexOf('callrestorationpro.com') > -1 || page.indexOf('modernize.com') > -1 || page.indexOf('powerhomedeals.com') > -1) {
		let service = window.modForm.opts.landingPage.replace(/\/$/g, '').replace(/^(?:[^/]*\/)+/g, '');
		service = (typeof defaultQuadLinkValues[page][service] !== 'undefined') ? service : 'default';
		return defaultQuadLinkValues[page][service];
	}

	return defaultQuadLinkValues[page];
}
