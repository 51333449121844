import {
	getFormData,
	getFormsServiceApiDomain,
	mapDynamicServiceCode,
	mapServiceSpecificAttributes
} from '../../helpers';
/**
 * Submit trade-specific answers to HS Forms Service to get Consent Vendor Listings
 */
export function getConsentListing() {
	window.modForm.opts.gettingLiveConsent = true;

	const apiDomain = getFormsServiceApiDomain(),
		apiUrl = apiDomain + 'forms/consent-searches',
		providersWrapper = document.querySelector('.tcpa-providers'),
		targetDiv = document.querySelector('[data-providers-consent]'),
		formData = getFormData();
	
	const lowerCasedQuadLinkData = Object.fromEntries(
		Object.entries(window.modForm.opts.quadLinkParams).map(([key, value]) => [key.toLowerCase(), value])
	);

	const quadLinkData = window.modForm.opts.quadLinkData.quadlink ? { 'quadLink': window.modForm.opts.quadLinkData.quadlink } : lowerCasedQuadLinkData,
		service = mapDynamicServiceCode(formData),
		partnerSourceID = formData.PartnerSourceID ? { partnerSourceID: formData.PartnerSourceID } : {};

	mapServiceSpecificAttributes(service);

	const consentSearchData = {
		'zip': window.modForm.opts.getVars.zip ? window.modForm.opts.getVars.zip : window.modForm.opts.zipCode,
		'service': service,
		'site': window.modForm.opts.quadLinkData.WebSiteName,
		...quadLinkData,
		...partnerSourceID,
		'serviceSpecificAttributes': {
			...window.modForm.opts.serviceSpecificAttributes
		}
	};

	if (JSON.stringify(consentSearchData) === window.modForm.opts.consentCaptureDetails) {
		return;
	}

	window.modForm.opts.consentCaptureDetails = JSON.stringify(consentSearchData);

	let response = {};
	const request = new XMLHttpRequest();
	request.open('POST', apiUrl);
	request.setRequestHeader('Content-Type', 'application/json');
	request.send(JSON.stringify(consentSearchData));
	request.addEventListener('load', function() {
		if (this.response) {
			response = { ...response, ...JSON.parse(this.response) };
			if (this.readyState === 4 && this.status === 200) {
				if (response.data.length === 1 && response.data[0].vendorDisplayName === 'Modernize') {
					// If Modernize is the only response – remove the "Providers" section
					if (providersWrapper) {
						providersWrapper.style.display = 'none';
					}
				} else {
					if (providersWrapper && providersWrapper.style.display === 'none') {
						providersWrapper.style.display = 'block';
					}
				}

				if (response.requestId) {
					window.modForm.opts.consentRequestId = response.requestId;
				}

				if (JSON.stringify(response)) {
					if (targetDiv.hasChildNodes()) {
						while (targetDiv.firstChild) {
							targetDiv.removeChild(targetDiv.firstChild);
						}
					}
					window.modUtils.renderConsentCheckboxes(response.data, targetDiv);
				}
			}
		}
	});

	request.addEventListener('error', function() {
		console.error('Something went wrong!');
	});
}
